import { createAsyncThunk } from '@reduxjs/toolkit';

import { myBooksApi } from '~/api/account/myBooksApi';
import {
  ChangeBookStatusParams,
  ChangeMyBookStatus,
  DeleteMyBookParams,
  HiddenBookParams,
  MyBookStatusEnum,
} from '~/api/account/myBooksApiTypes';
import { WithToken } from '~/api/auth/authApiTypes';
import { myBooksActions, myBooksSliceName } from '~/atomic/page/mybooks/myBooks.slice';
import { getGraphqlBook } from '~/feature/book/book.data';
import { bookActions } from '~/feature/book/book.slice';
import {
  catalogFilterGenresSelector,
  catalogFilterSelector,
} from '~/feature/catalog/filter/catalogFilter.selector';
import { paginationActions } from '~/feature/pagination/pagination.slice';
import { ym, ymOther } from '~/feature/yandex/YandexMetrikaInit';
import { environments } from '~/lib/const';
import { RootState } from '~/store';

export const getMyBooks = createAsyncThunk<
void,
WithToken | void,
{ rejectValue: { error: string }; state: RootState }
>(
  `${myBooksSliceName}/getMyBooks`,
  async (
    data,
    thunkAPI,
  ) => {
    thunkAPI.dispatch(myBooksActions.setLoading(true));
    try {
      const {
        completeStatus,
        isOnlyFree,
        bookType,
      } = catalogFilterSelector(thunkAPI.getState());
      const genres = catalogFilterGenresSelector(thunkAPI.getState());
      const { tabName, reactionFilter } = thunkAPI.getState().myBooks;
      const paging = thunkAPI.getState().pagination;

      if (tabName === 'viewed') {
        await thunkAPI.dispatch(getMyViewedBooks(data));
        return;
      }

      let reaction;

      if (tabName === 'reaction') {
        reaction = reactionFilter;
      }

      const statuses = {
        read: MyBookStatusEnum.read,
        readLater: MyBookStatusEnum.readLater,
        readNow: MyBookStatusEnum.readNow,
      }[tabName] as MyBookStatusEnum;

      const result = await myBooksApi.getMyBooks({
        page: paging.page,
        perPage: paging.perPage,
        genreSlugs: genres,
        statusComplete: completeStatus,
        statuses,
        free: isOnlyFree === false ? undefined : true,
        type: bookType || undefined,
        token: data && data?.token ? data.token : undefined,
        reaction,
      });

      if (result && 'data' in result) {
        thunkAPI.dispatch(paginationActions.setPage({ page: result.currentPage }));
        thunkAPI.dispatch(paginationActions.setPageSize({ perPage: result.perPage }));
        thunkAPI.dispatch(paginationActions.setTotal({ total: result.total }));

        thunkAPI.dispatch(myBooksActions.changeBooks({
          tabName, books: result.data, total: result.total,
        }));
      }
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    } finally {
      thunkAPI.dispatch(myBooksActions.setLoading(false));
    }
  },
);

export const getMyViewedBooks = createAsyncThunk<
void,
WithToken | void,
{ rejectValue: { error: string }; state: RootState }
>(
  `${myBooksSliceName}/getMyViewedBooks`,
  async (
    data,
    thunkAPI,
  ) => {
    thunkAPI.dispatch(myBooksActions.setLoading(true));

    try {
      const paging = thunkAPI.getState().pagination;

      const result = await myBooksApi.getMyViewedBooks({
        ...paging,
        token: data && data?.token ? data.token : undefined,
      });

      if (result && 'data' in result) {
        thunkAPI.dispatch(paginationActions.setPage({ page: result.currentPage }));
        thunkAPI.dispatch(paginationActions.setPageSize({ perPage: result.perPage }));
        thunkAPI.dispatch(paginationActions.setTotal({ total: result.total }));

        thunkAPI.dispatch(myBooksActions.changeBooks({
          tabName: 'viewed', books: result.data, total: result.total,
        }));
      }
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    } finally {
      thunkAPI.dispatch(myBooksActions.setLoading(false));
    }
  },
);

export const clearViewedBooks = createAsyncThunk<
void,
void,
{ rejectValue: { error: string }; }
>(
  `${myBooksSliceName}/clearViewedBooks`,
  async (
    _,
    thunkAPI,
  ) => {
    try {
      await myBooksApi.clearViewedBooks();
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export interface ChangeBookStatusThunkParams extends ChangeBookStatusParams {
  currentStatus: MyBookStatusEnum;
}

export const changeBookStatus = createAsyncThunk<
ChangeMyBookStatus,
ChangeBookStatusThunkParams,
{ rejectValue: { error: string }, state: RootState }
>(
  `${myBooksSliceName}/changeBookStatus`,
  async (
    data,
    thunkAPI,
  ) => {
    const { bookPage, currentGraphqlBook } = thunkAPI.getState().book;
    thunkAPI.dispatch(bookActions.setChangeMyBookStatusLoading(true));
    const { currentStatus, ...apiData } = data;

    try {
      if (!currentStatus) {
        await myBooksApi.addMyBooks(apiData);
        ym('reachGoal', 'book-add-in-my-books');
        if (bookPage?.author?.yandexMetrika) {
          ymOther(bookPage?.author.yandexMetrika, 'reachGoal', 'book-add-in-my-books');
        }
        if (currentGraphqlBook?.author?.yandexMetrika) {
          ymOther(currentGraphqlBook?.author.yandexMetrika, 'reachGoal', 'book-add-in-my-books');
        }
      } else {
        await myBooksApi.changeBookStatus(apiData);
      }
      if (bookPage?.id) {
        thunkAPI.dispatch(bookActions.changeMyBookPageStatus({ status: data.status }));
      }
      if (currentGraphqlBook?.id) {
        thunkAPI.dispatch(bookActions.changeMyBookStatus({ status: data.status }));
      }
      return { status: data.status };
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    } finally {
      thunkAPI.dispatch(bookActions.setChangeMyBookStatusLoading(false));
    }
  },
);

export const changeBookStatusWithBookUpdate = createAsyncThunk<
void,
ChangeBookStatusThunkParams
>(
  `${myBooksSliceName}/changeBookStatusWithBookUpdate`,
  async (data, thunkAPI) => {
    thunkAPI.dispatch(myBooksActions.setIsChangeBookStatusWithBookLoading(true));
    await thunkAPI.dispatch(changeBookStatus({
      bookId: data.bookId,
      currentStatus: data.currentStatus,
      status: data.status,
    }));

    await thunkAPI.dispatch(getGraphqlBook({ id: data.bookId }));
    thunkAPI.dispatch(myBooksActions.setIsChangeBookStatusWithBookLoading(false));
  },
);

export const hiddenBook = createAsyncThunk<
void,
HiddenBookParams,
{ rejectValue: { error: string }; }
>(
  `${myBooksSliceName}/hiddenBook`,
  async (
    data,
    thunkAPI,
  ) => {
    try {
      await myBooksApi.hiddenBook(data);
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const cancelHiddenBook = createAsyncThunk<
void,
HiddenBookParams,
{ rejectValue: { error: string }; }
>(
  `${myBooksSliceName}/cancelHiddenBook`,
  async (
    data,
    thunkAPI,
  ) => {
    try {
      await myBooksApi.cancelHiddenBook(data);
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const deleteMyBook = createAsyncThunk<
void,
DeleteMyBookParams,
{ rejectValue: { error: string }, state: RootState }
>(
  `${myBooksSliceName}/deleteMyBook`,
  async (
    data,
    thunkAPI,
  ) => {
    try {
      const { bookPage, currentGraphqlBook } = thunkAPI.getState().book;
      await myBooksApi.deleteMyBook(data);

      if (bookPage?.id) {
        thunkAPI.dispatch(bookActions.changeMyBookPageStatus({ status: null }));
      }
      if (currentGraphqlBook?.id) {
        thunkAPI.dispatch(bookActions.changeMyBookStatus({ status: null }));
      }
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);
