import React, {
  HTMLAttributes, useEffect, useState,
} from 'react';
import styled from 'styled-components';

import {
  Book, BookRecommender, MiniatureSizesEnum,
} from '~/api/book/bookApiTypes';
import { Avatar } from '~/atomic/atom/Avatar/Avatar';
import { Button } from '~/atomic/atom/Button';
import { AuthorLink } from '~/atomic/atom/links/dynamicLinks';
import Modal from '~/atomic/molecula/Modal';
import { TextRegStyle, TextSmallStyle } from '~/atomic/Typography';
import { isMobileSizeSelector } from '~/feature/media/windowSize.selector';
import {
  bookFeedback,
  deleteBookFeedback,
} from '~/feature/recommendations/recommendations.slice';
import { lessThan, moreThan } from '~/lib/mediaQuery';
import { useAppDispatch, useAppSelector } from '~/store';

export type BookCardRecommendationProps = {
  book: Book;
  isHideRecommendation: boolean;
  changeIsHideRecommendation: (isHide: boolean) => void;
  containerProps?: HTMLAttributes<HTMLDivElement>;
  isShowModalFromProps?: boolean;
};

export const BookCardRecommendation = ({
  book,
  isHideRecommendation,
  changeIsHideRecommendation,
  isShowModalFromProps = false,
  containerProps,
  ...props
}: BookCardRecommendationProps & HTMLAttributes<HTMLDivElement>) => {
  const dispatch = useAppDispatch();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const isMobile = useAppSelector(isMobileSizeSelector);
  const isShowModal = isMobile || isShowModalFromProps;
  const [recommendationRating, changeRecommendationRating] = useState(
    book?.recommendation?.feedbackRating ?? null as boolean,
  );

  const onClickRecommendationHandler = async (rating: boolean) => {
    const result = await dispatch(bookFeedback({
      bookId: book.id,
      rating,
    }));
    if (bookFeedback.fulfilled.match(result)) {
      changeRecommendationRating(rating);
      setIsOpenModal(false);

      if (rating === false) {
        const messageKey = 'delete_recommendation';
        const { message } = await import('~/atomic/atom/message');
        message.info({
          content: (
            <span>
              Нажмите на меня, чтобы отменить оценку рекомендации
            </span>
          ),
          key: messageKey,
          onClick: async () => {
            await dispatch(deleteBookFeedback({ bookId: book.id }));
            message.destroy(messageKey);
            message.success('Оценка успешно удалена');
            changeRecommendationRating(null as boolean);
          },
          style: { cursor: 'pointer' },
        });
      }
    }
  };

  const RecommendationAvatar = ({
    recommender,
    index,
  }: { recommender: BookRecommender, index: number }) => (
    <AuthorLink
      username={recommender.username}
      key={recommender.username}
    >
      <SCAvatarLink>
        <SCAvatar
          style={{ zIndex: index + 1 }}
          avatarSize={MiniatureSizesEnum.L}
          avatarImages={recommender.avatarImages}
          userName={recommender?.name}
          imageSize={38}
        />
      </SCAvatarLink>
    </AuthorLink>
  );

  const openRecommendationModalHandler = async () => {
    if (!isOpenModal && isShowModal) {
      setIsOpenModal(true);
    }
  };

  useEffect(() => {
    changeIsHideRecommendation(
      recommendationRating === false,
    );
  }, [recommendationRating]);

  const content = () => (
    <>
      <SCTitle>
        Ваши книжные интересы совпадают:
      </SCTitle>
      <SCUsers>
        {book?.recommendation?.totalRecommenders > 9 && (
          <>
            {book?.recommendation?.recommenders
              .filter((_, index) => index < 4)
              .map((recommender, index) => (
                <RecommendationAvatar key={recommender.username} recommender={recommender} index={index} />
              ))}
            <SCCount>
              +
              {book.recommendation.totalRecommenders - 4}
            </SCCount>
          </>
        )}
        {book?.recommendation?.totalRecommenders > 5
          && book?.recommendation?.totalRecommenders < 10
          && (
            <>
              {book?.recommendation?.recommenders?.map((recommender, index) => (
                <RecommendationAvatar key={recommender.username} recommender={recommender} index={index} />
              ))}
              <SCCount>
                +
                {book.recommendation.totalRecommenders - 5}
              </SCCount>
            </>
          )}
        {book?.recommendation?.totalRecommenders < 6
          && book?.recommendation?.recommenders?.map((recommender, index) => (
            <RecommendationAvatar key={recommender.username} recommender={recommender} index={index} />
          ))}
      </SCUsers>
      {recommendationRating === null && (
        <>
          <SCEstimation>
            Оцените рекомендацию
          </SCEstimation>
          <SCButtons>
            <SCButton
              onClick={() => [
                onClickRecommendationHandler(true),
              ]}
            >
              <img src="/icon/like.svg" alt="лайк" />
            </SCButton>
            <SCButton
              onClick={() => [
                onClickRecommendationHandler(false),
              ]}
            >
              <img src="/icon/dislike.svg" alt="дизлайк" />
            </SCButton>
          </SCButtons>
        </>
      )}
      {recommendationRating === true && (
        <SCEstimation>
          Рады, что рекомендация понравилась
        </SCEstimation>
      )}
      {recommendationRating === false && (
        <SCEstimation>
          Похожие книги не будут вам рекомендоваться
        </SCEstimation>
      )}
    </>
  );

  if (!book?.recommendation) {
    return null;
  }

  if (isHideRecommendation === true) {
    return (
      <SCRecommendationContainer
        {...containerProps}
      >
        <SCBadRecommendation>
          книга не для меня
        </SCBadRecommendation>
      </SCRecommendationContainer>
    );
  }

  return (
    <SCRecommendationContainer
      onMouseLeave={() => {
        if (recommendationRating === false) {
          changeIsHideRecommendation(true);
        }
      }}
      onClick={openRecommendationModalHandler}
      {...containerProps}
    >
      <SCRecommendation
        {...props}
      >
        {!!book?.recommendation?.totalRecommenders
          && book?.recommendation?.totalRecommenders}
        {' '}
        рекомендуют
      </SCRecommendation>
      {!isShowModal ? (
        <SCWindow>
          {content()}
        </SCWindow>
      ) : null}
      {isShowModal ? (
        <SCModal
          open={isOpenModal}
          maskStyle={{ backgroundColor: 'rgb(255 255 255 / 40%)' }}
          onCancel={() => {
            setIsOpenModal(false);
          }}
        >
          {content()}
        </SCModal>
      ) : null}
    </SCRecommendationContainer>
  );
};

const SCRecommendation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  top: 100%;
  background-color: #CFF8E0;
  border-radius: 2px;
  width: 100%;
  margin-top: 6px;
  height: 18px;

  font-family: var(--second-font);
  font-style: normal;
  font-weight: bold;
  font-size: 10px;

  letter-spacing: 0.05em;
  text-transform: uppercase;

  color: var(--green3-color);

  ${lessThan('sm')} {
    font-size: 9px;
  }
`;

const SCBadRecommendation = styled(SCRecommendation)`
  background-color: #F8CFCF;
  color: #EA1B4D;
  cursor: default;
`;

const SCWindow = styled.div`
  position: absolute;
  bottom: 110%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  background: var(--black-color);
  box-shadow: 0 3px 5px rgba(53, 63, 72, 0.25);
  border-radius: 4px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  padding: 12px;
  z-index: 3;

  &:before {
    content: '';
    width: 100%;
    height: 10px;
    bottom: -10px;
    display: flex;
    position: absolute;
  }

  &:after {
    content: '';
    display: flex;
    width: 20px;
    height: 20px;
    background: var(--black-color);
    position: absolute;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    bottom: -2px;
  }
`;

const SCRecommendationContainer = styled.div`
  width: 100%;
  cursor: pointer;
  position: relative;

  ${moreThan('sm')} {
    &:hover {
      ${SCWindow} {
        opacity: 1;
        pointer-events: all;
      }
    }
  }
`;

const SCUsers = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

const SCCount = styled.span`
  ${TextRegStyle};
  color: var(--gray-color);
  padding-left: 5px;
  display: flex;
`;

const SCAvatar = styled(Avatar)`
  background-color: var(--white-color);
  border: 2px solid var(--black-color);
`;

const SCAvatarLink = styled.a`
  &:not(:first-child) {
    margin-left: -14px;
  }
`;

const SCEstimation = styled.span`
  ${TextSmallStyle};
  display: flex;
  margin-bottom: 7px;
  color: var(--gray3-color);
`;

const SCTitle = styled.span`
  ${TextSmallStyle};
  display: flex;
  margin-bottom: 2px;
  color: var(--gray3-color);
`;

const SCButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
`;

const SCButton = styled(Button)` && {
  background: rgba(127, 135, 148, 0.25);
  border: 1px solid rgba(127, 135, 148, 0.25);

  &:hover {
    background: none;
    border: 1px solid #fff;;
  }
}
`;

const SCModal = styled(Modal)`
  width: 320px !important;
  
  .ant-modal-body {
    background: var(--black-color);
  }
`;
