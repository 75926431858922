import { MyBookStatusEnum } from '~/api/account/myBooksApiTypes';
import {
  AgeRatingEnum,
  Author,
  BookDiscount,
  BookIdType,
  BookRecommendation,
  BookTypeEnum,
  CompleteStatusEnum,
  CoverImage,
  PublishStatusEnum,
} from '~/api/book/bookApiTypes';
import { ChapterStatus } from '~/api/book/chapterApiTypes';
import { Genre } from '~/api/book/genreApiTypes';
import { GraphqlEChapter } from '~/graphql/books/factory/book/EBookChapterFactoryTypes';

export interface GraphqlCycle {
  id: number;
  name: string;
  slug: string;
  numInCycle: number;
}

export enum BookReactionRatingEnum {
  NotRated = 'not_rated',
  Bad = 'bad',
  SoSo = 'so_so',
  WillDo = 'will_do',
  Well = 'well',
  Great = 'great',
}

export enum CamelCaseBookReactionRatingEnum {
  NotRated = 'notRated',
  Bad = 'bad',
  SoSo = 'soSo',
  WillDo = 'willDo',
  Well = 'well',
  Great = 'great',
}

export interface BookReaction {
  rating: BookReactionRatingEnum;
}

export interface BookPurchaseStatus {
  price: number;
  boughtWithTimerDiscount: boolean;
}

export interface GraphqlEBook {
  bookId: BookIdType;
  price: number;
  available: boolean;
  canBuy: boolean;
  bought: boolean;
  discount: BookDiscount;
  purchase?: BookPurchaseStatus | null;
  addedToAbonnement?: boolean;
}

export interface GraphqlEBookWithChapters extends GraphqlEBook {
  chapters: Array<GraphqlEChapter>;
}

export interface GraphqlAudioBook {
  bookId: BookIdType;
  price: number;
  available: boolean;
  canBuy: boolean;
  canListen: boolean;
  bought: boolean;
  discount: BookDiscount;
  purchase?: BookPurchaseStatus | null;
}

export interface GraphqlAudioChapter {
  chapterId: number;
  name: string;
  position: number;
  status: ChapterStatus;
  available: boolean;
  createdAt: string;
  updatedAt: string;
  firstPublishedAt: string;
  free: boolean;
  associatedTextChapterId: number;
  audioUrl: string;
  duration: number;
  voicingStatus: string;
}

export interface GraphqlAudioBookWithChapters extends GraphqlAudioBook {
  chapters: Array<GraphqlAudioChapter>;
}

export interface GraphqlBook {
  id: number;
  name: string;
  slug: string;
  free: boolean;
  exclusive: boolean;
  symbols: number;
  authorPages: number;
  annotation: string;
  audiobook?: GraphqlAudioBook;
  ebook?: GraphqlEBook;
  coverImages?: Array<CoverImage>;
  statusPublish: PublishStatusEnum;
  statusComplete: CompleteStatusEnum;
  age: AgeRatingEnum;
  type: BookTypeEnum;
  cycle: GraphqlCycle;
  author: Author;
  createdAt: string;
  updatedAt: string;
  myBook: {
    symbols?: number;
    bought: boolean;
    hasReview: boolean;
    note?: string;
    reaction: BookReaction;
    readStatus: MyBookStatusEnum;
  };
  redLine: boolean;
  isAvailableEdit: boolean;
  allowToSellBookByChapter: boolean;
  chapterPrice: number | null;
  spentOnChapters: number;
  partnerProgram?: {
    percent: number;
  };
  genres: Array<Genre>;
  hideCover: boolean;
  recommendation: BookRecommendation;
}
