import React, { FC } from 'react';
import styled from 'styled-components';

import { MyBookStatusEnum } from '~/api/account/myBooksApiTypes';
import { MyBooksStatusIcon } from '~/atomic/atom/MyBooksStatusIcon';

interface BookCardReadStatusBadgerProps {
  status: MyBookStatusEnum;
}

export const BookCardReadStatusBadger: FC<BookCardReadStatusBadgerProps> = ({ status }) => {
  if (!status || status === MyBookStatusEnum.NotRead) {
    return <></>;
  }

  const renderIcon = () => {
    if (status === MyBookStatusEnum.read) {
      return <SCReadBookStatus status={status} />;
    }
    if (status === MyBookStatusEnum.readNow) {
      return <SCReadNowBookStatus status={status} />;
    }
    if (status === MyBookStatusEnum.readLater) {
      return <SCReadLaterBookStatus status={status} />;
    }
  };

  return (
    <SCMyBookStatusBadger>
      {renderIcon()}
    </SCMyBookStatusBadger>
  );
};

const SCMyBookStatusBadger = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 30px;
  height: 30px;
  border-bottom-right-radius: 60%;
  z-index: 3;
  background-color: var(--white-color);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SCReadBookStatus = styled(MyBooksStatusIcon)`
  font-size: 16px;
  color: var(--black-color);
`;

const SCReadNowBookStatus = styled(MyBooksStatusIcon)`
  font-size: 18px;
  color: var(--black-color);
`;

const SCReadLaterBookStatus = styled(MyBooksStatusIcon)`
  font-size: 18px;
  color: var(--black-color);
`;
