import { unwrapResult } from '@reduxjs/toolkit';
import React, { FC } from 'react';
import styled from 'styled-components';

import { ListenIcon } from '~/atomic/atom/icon/ListenIcon';
import { loadAudioPlayer, openAudioPlayer } from '~/feature/audio/audioPlayer.data';
import { isAvailableListerAudioBookSelector } from '~/feature/book/book.selector';
import { DefaultBookCardProps } from '~/feature/book/BookCard/DefaultBookCard';
import { getPublishedChapterListByBookSlugFromGraphql } from '~/feature/chapter/chapter.data';
import { useAppDispatch, useAppSelector } from '~/store';

export interface BookCardOverlayListenButtonProps extends Pick<DefaultBookCardProps, 'book'> {

}

export const BookCardOverlayListenButton: FC<BookCardOverlayListenButtonProps> = ({ book }) => {
  const dispatch = useAppDispatch();
  const isAvailableListerAudioBook = useAppSelector(isAvailableListerAudioBookSelector(book));

  const listenAudiobookHandler = async () => {
    if (isAvailableListerAudioBook) {
      const graphqlChapters = unwrapResult(
        await dispatch(getPublishedChapterListByBookSlugFromGraphql({ slug: book.slug })),
      );
      await dispatch(loadAudioPlayer({
        book: {
          author: book.authors?.[0],
          name: book.name,
          id: book.id,
          slug: book.slug,
        },
        chapters: graphqlChapters
          ?.audioChapters
          ?.map((chapter) => ({
            id: chapter.chapterId,
            name: chapter.name,
            url: chapter?.audioUrl,
            available: chapter?.available,
            duration: chapter?.duration,
          })),
      }));
      dispatch(openAudioPlayer());
    }
  };

  return (
    <SCBookCardOverlayListenButton
      data-is-available-listen={isAvailableListerAudioBook}
      onClick={listenAudiobookHandler}
    >
      <SCListenIcon />
    </SCBookCardOverlayListenButton>
  );
};

const SCBookCardOverlayListenButton = styled.div`
  background-color: var(--yellow-dark-color);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &[data-is-available-listen="false"] {
    cursor: default;
    filter: contrast(0.4) brightness(1.1);
    pointer-events: none;
  }
`;

const SCListenIcon = styled(ListenIcon)`
  width: 22px;
  height: 22px;
  fill: var(--black-color);
`;
