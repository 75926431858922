import { createAsyncThunk } from '@reduxjs/toolkit';
import Router from 'next/router';
import qs from 'query-string';

import { openBuyBookModal } from '~/feature/book/buyBook/buyBook.data';
import { isCanNotBuyBookPageSelector } from '~/feature/book/buyBook/buyBook.selector';
import { RootState } from '~/store';

export const buyBookActionName = 'buyBook';

export const checkBuyBookAction = createAsyncThunk<void, void, {
  state: RootState;
}>(
  'bookPage/checkBuyBookAction',
  async (_, thunkAPI) => {
    const isCannotBuyBook = isCanNotBuyBookPageSelector(thunkAPI.getState());
    const { bookPage } = thunkAPI.getState().book;
    const { user } = thunkAPI.getState().user;
    const isCurrentUserAuthorBook = String(bookPage?.author?.id) === String(user.id);

    const parsedUrl = qs.parseUrl(String(window.location));

    const action = parsedUrl.query?.action as string;

    if (action === buyBookActionName) {
      parsedUrl.query.action = undefined;

      const url = new URL(qs.stringifyUrl(parsedUrl));

      const redirectUrl = url.pathname + url.search + url.hash;

      await Router.replace(Router.pathname, redirectUrl, { shallow: true });

      if (!isCannotBuyBook) {
        thunkAPI.dispatch(openBuyBookModal());
      }
    }
  },
);
