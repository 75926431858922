import React, { FC, HTMLAttributes } from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';

import { Book, BookDiscount } from '~/api/book/bookApiTypes';
import { SCAgeRating } from '~/atomic/atom/AgeRating';
import { DefaultBookCardProps } from '~/feature/book/BookCard/DefaultBookCard';
import {
  SCDiscountBadgeBookCard,
  SCPartnerPercentBadgeBookCard,
} from '~/feature/book/BookCard/styled';
import { ReactionBadger } from '~/feature/myBooks/reactions/ReactionBadger';
import { BookReactionRatingEnum, GraphqlBook } from '~/graphql/books/factory/book/BookFactoryTypes';
import { lessThan } from '~/lib/mediaQuery';

interface BookCardBadgersProps extends Pick<DefaultBookCardProps, 'isShowPartnerProgram'>, HTMLAttributes<HTMLDivElement> {
  discountBadgeCss?: FlattenSimpleInterpolation;
  discount: BookDiscount;
  bought: boolean;
  book: Partial<Book | GraphqlBook>;
  isShowReactionBadger?: boolean;
}

export const BookCardBadgers: FC<BookCardBadgersProps> = ({
  book,
  isShowPartnerProgram,
  discountBadgeCss,
  discount,
  bought,
  isShowReactionBadger = true,
  ...props
}) => {
  const isShowDiscount = !!(discount?.percent) && !bought;

  const isShowAgeBadge = !!book.age && Number.parseInt(book.age, 10) === 18;

  const isShowReaction = !!book?.myBook?.reaction
    && !!book?.myBook?.reaction?.rating
    && isShowReactionBadger
    && book?.myBook?.reaction?.rating !== BookReactionRatingEnum.NotRated;

  const isShowBadge = isShowDiscount
    || (isShowPartnerProgram && book?.partnerProgram?.percent > 0)
    || isShowAgeBadge
    || isShowReaction;

  if (!isShowBadge) return null;

  return (
    <SCBookCardBadgers {...props}>
      {isShowDiscount && (
        <SCDiscountBadgeBookCard css={discountBadgeCss}>
          {`-${discount?.percent ?? 0}%`}
        </SCDiscountBadgeBookCard>
      )}
      {isShowPartnerProgram && book?.partnerProgram?.percent > 0 && (
        <SCPartnerPercentBadgeBookCard>
          {`+${book?.partnerProgram?.percent}%`}
        </SCPartnerPercentBadgeBookCard>
      )}
      {isShowReaction
        ? <SCReactionBadger data-is-show-age-rating={isShowAgeBadge} reaction={book?.myBook?.reaction} />
        : null}
      {isShowAgeBadge && (
        <SCAgeRating>
          {book.age}
        </SCAgeRating>
      )}
    </SCBookCardBadgers>
  );
};

const SCBookCardBadgers = styled.div`
  position: absolute;
  bottom: 3%;
  right: 3%;
  width: 94%;
  z-index: 2;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  pointer-events: none;

  > *:not(:last-child) {
    margin-right: 5px;
  }

  ${lessThan('sm')} {
    right: 5%;
    width: 90%;
    bottom: 4%;
  }
`;

const SCReactionBadger = styled(ReactionBadger)`
  &[data-is-show-age-rating="true"] {
    && {
      margin-right: -6px;
      z-index: 1;
    }
  }
`;
