import { ParsedUrlQueryInput } from 'querystring';
import React, { FC } from 'react';
import styled from 'styled-components';

import { OpenBookIcon } from '~/atomic/atom/icon/OpenBookIcon';
import { ReaderLink } from '~/atomic/atom/links/dynamicLinks';
import { DefaultBookCardProps } from '~/feature/book/BookCard/DefaultBookCard';

interface BookCardOverlayReadButtonProps extends Pick<DefaultBookCardProps, 'book'> {
  query?: ParsedUrlQueryInput;
  onClick?: () => void;
}

export const BookCardOverlayReadButton: FC<BookCardOverlayReadButtonProps> = ({
  book, onClick, query,
}) => {
  if (!book?.ebook?.available) {
    return null;
  }

  return (
    <ReaderLink
      bookSlug={book.slug}
      query={query}
    >
      <a onClick={onClick}>
        <SCReadBook>
          <SCOpenBookIcon />
        </SCReadBook>
      </a>
    </ReaderLink>
  );
};

const SCReadBook = styled.div`
  background-color: var(--yellow-dark-color);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  transition: transform 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SCOpenBookIcon = styled(OpenBookIcon)`
  font-size: 18px;
  color: var(--black-color);
`;
