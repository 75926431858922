import { unwrapResult } from '@reduxjs/toolkit';
import React, { FC, useState } from 'react';
import styled from 'styled-components';

import { MyBookStatusEnum } from '~/api/account/myBooksApiTypes';
import { Dropdown } from '~/atomic/atom/Dropdown';
import { LoaderIcon } from '~/atomic/atom/icon/LoaderIcon';
import { MyBooksStatusIcon } from '~/atomic/atom/MyBooksStatusIcon';
import {
  changeBookStatus, deleteMyBook, hiddenBook,
} from '~/atomic/page/mybooks/myBooks.data';
import { openModal } from '~/feature/authorization/authorizationModal.slice';
import { BookCardOverlayProps } from '~/feature/book/BookCard/BookCardOverlay';
import { ReadBookStatusList } from '~/feature/book/ReadBookStatusList';
import { isLoggedInSelector } from '~/feature/user/isLoggedInSelector';
import { useAppDispatch, useAppSelector } from '~/store';

interface BookCardStatusProps extends Partial<Pick<
BookCardOverlayProps,
'book' | 'setSelectedType' | 'selectedType' | 'setIsHideBook' | 'onChangeMyBookStatus' | 'setIsShowOverlay'
>> {}

export const BookCardStatus: FC<BookCardStatusProps> = ({
  selectedType,
  setIsHideBook,
  setSelectedType,
  book,
  onChangeMyBookStatus,
  setIsShowOverlay,
}) => {
  const dispatch = useAppDispatch();
  const [myBookLoading, setMyBookLoading] = useState(false);
  const isLoggedIn = useAppSelector(isLoggedInSelector);

  const changeUserStatus = (status: MyBookStatusEnum) => {
    // TODO: Вынести проверку на пользователя в экшн changeBookStatus
    if (!isLoggedIn) {
      dispatch(openModal('login'));
    } else {
      setMyBookLoading(true);
      dispatch(changeBookStatus({
        bookId: book.id,
        status,
        currentStatus: selectedType,
      }))
        .then(unwrapResult)
        .then((res) => {
          setSelectedType(res.status);
          if (onChangeMyBookStatus) onChangeMyBookStatus();
        })
        .finally(() => {
          setMyBookLoading(false);
        });
    }
  };

  const onDeleteUserStatusHandler = () => {
    // TODO: Вынести проверку на пользователя в экшн deleteMyBook
    if (!isLoggedIn) {
      dispatch(openModal('login'));
    } else {
      setMyBookLoading(true);
      dispatch(deleteMyBook({ bookId: book.id }))
        .then(unwrapResult)
        .then(() => {
          setSelectedType(null);
        })
        .finally(() => {
          setMyBookLoading(false);
        });
    }
  };

  const hiddenBookHandler = async () => {
    if (!isLoggedIn) {
      dispatch(openModal('login'));
    } else {
      const result = await dispatch(hiddenBook({ bookId: book.id }));
      if (hiddenBook.fulfilled.match(result)) {
        if (setIsHideBook) {
          setIsHideBook(true);
        }
        setIsShowOverlay(false);
      }
    }
  };

  const renderIcon = () => {
    if (myBookLoading) {
      return <SCLoaderIcon />;
    }

    if (selectedType === MyBookStatusEnum.read) {
      return <SCReadBookStatus status={selectedType} />;
    }
    if (selectedType === MyBookStatusEnum.readNow) {
      return <SCReadNowBookStatus status={selectedType} />;
    }
    if (selectedType === MyBookStatusEnum.readLater) {
      return <SCReadLaterBookStatus status={selectedType} />;
    }

    return <SCAddInMyBooks status={selectedType} />;
  };

  if (!onChangeMyBookStatus) {
    return (
      <SCBookCardStatus>
        {renderIcon()}
      </SCBookCardStatus>
    );
  }

  return (
    <Dropdown
      overlay={() => (
        <SCBookStatusContainer>
          <ReadBookStatusList
            status={selectedType}
            setStatus={changeUserStatus}
            onDelete={onDeleteUserStatusHandler}
            isShowHideBook
            onHide={hiddenBookHandler}
          />
        </SCBookStatusContainer>
      )}
      disabled={myBookLoading}
      trigger={['hover']}
    >
      <SCBookCardStatus>
        {renderIcon()}
      </SCBookCardStatus>
    </Dropdown>
  );
};

const SCBookCardStatus = styled.div`
  background-color: var(--black-color);
  max-width: 36px;
  max-height: 36px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SCBookStatusContainer = styled.div`
  background-color: var(--white-color);
  border: 1px solid var(--gray3-color);
  box-sizing: border-box;

  box-shadow: 0 3px 5px rgba(53, 63, 72, 0.25);
  border-radius: 2px;
  padding: 12px 8px;
  margin-left: -17px;
`;

const SCReadBookStatus = styled(MyBooksStatusIcon)`
  font-size: 18px;
  color: var(--white-color);
`;

const SCReadNowBookStatus = styled(MyBooksStatusIcon)`
  font-size: 22px;
  color: var(--white-color);
`;

const SCReadLaterBookStatus = styled(MyBooksStatusIcon)`
  font-size: 20px;
  color: var(--white-color);
`;

const SCAddInMyBooks = styled(MyBooksStatusIcon)`
  font-size: 20px;
  color: var(--white-color);
`;

const SCLoaderIcon = styled(LoaderIcon)`
  width: 24px;
  height: 24px;
`;
