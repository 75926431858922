import React, { FC } from 'react';
import styled from 'styled-components';

import { TextSmallStyle } from '~/atomic/Typography';

interface NewSymbolsProps {
  newSymbols?: number;
  currentSymbols?: number;
}

export const NewSymbols: FC<NewSymbolsProps> = ({ newSymbols, currentSymbols }) => {
  if (!newSymbols || !currentSymbols) return null;
  const differentSymbols = currentSymbols - newSymbols;
  if (differentSymbols === 0) return null;
  const newSymbolsCount = Intl.NumberFormat('ru-Ru').format(differentSymbols);
  const isPositive = differentSymbols > 0;
  return (
    <SCNewSymbols data-is-positive={String(isPositive)}>
      { isPositive ? '+' : '-' }
      {newSymbolsCount}
    </SCNewSymbols>
  );
};

const SCNewSymbols = styled.div`
  position: absolute;
  right: 50%;
  bottom: 0%;
  transform: translateX(50%) translateY(50%);
  border-radius: 20px;
  ${TextSmallStyle};
  color: var(--white-color);
  padding: 0 5px;
  background: var(--error-color);

  &[data-is-positive="true"] {
    background: #27AE60;
  }
`;
