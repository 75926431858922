import { createAsyncThunk } from '@reduxjs/toolkit';

import { recommendationsApi } from '~/api/recommendations/recommendationsApi';
import {
  BookFeedbackParams,
  DeleteBookFeedbackParams,
} from '~/api/recommendations/recommendationsApiTypes';
import { RootState } from '~/store';

const recommendationSliceName = 'recommendation';

export const bookFeedback = createAsyncThunk<
void,
BookFeedbackParams,
{ rejectValue: { error: string }; state: RootState }
>(
  `${recommendationSliceName}/bookFeedback`,
  async (
    data,
    thunkAPI,
  ) => {
    try {
      const result = await recommendationsApi.bookFeedback(data);

      if (result?.message) {
        const { message } = await import('~/atomic/atom/message');
        message.success(result.message);
      }
    } catch (error) {
      const { message } = await import('~/atomic/atom/message');
      message.error(error.message);
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const deleteBookFeedback = createAsyncThunk<
void,
DeleteBookFeedbackParams,
{ rejectValue: { error: string }; state: RootState }
>(
  `${recommendationSliceName}/deleteBookFeedback`,
  async (
    data,
    thunkAPI,
  ) => {
    try {
      const result = await recommendationsApi.deleteBookFeedback(data);

      if (result?.message) {
        const { message } = await import('~/atomic/atom/message');
        message.success(result.message);
      }
    } catch (error) {
      const { message } = await import('~/atomic/atom/message');
      message.error(error.message);
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);
