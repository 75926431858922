export const numberShorter = (value: number) => {
  if (value < 1000) { return value; }
  const schema = ['', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
  const index = Math.floor(Math.log10(value) / 3);
  const prefix = (schema[index] === undefined ? (`e${index * 3}`) : schema[index]);

  // eslint-disable-next-line no-restricted-properties
  const result = (value / 10 ** (3 * index)).toFixed(1);

  return result + prefix;
};
