import React from 'react';
import styled from 'styled-components';

import { CompleteStatusEnum } from '~/api/book/bookApiTypes';
import { BookLink } from '~/atomic/atom/links/dynamicLinks';
import { buyBookActionName } from '~/atomic/page/book/Book/checkBuyBookAction';
import { TextBoldStyle } from '~/atomic/Typography';
import { DefaultBookCardProps } from '~/feature/book/BookCard/DefaultBookCard';
import { numberFormatter } from '~/lib/numberFormatter';

interface BookCardOverlayPriceProps extends Pick<DefaultBookCardProps, 'book' | 'bookLinkQueries'> {}

export const BookCardOverlayPrice = ({ book, bookLinkQueries }: BookCardOverlayPriceProps) => {
  const isHaveDiscount = (book.price !== book.discount?.price ?? '') && !book?.myBook?.buy;

  const BuyBook = () => (
    <BookLink
      slug={book.slug}
      query={{ action: buyBookActionName, ...bookLinkQueries }}
    >
      <SCBuyBook>{`${numberFormatter(book.discount?.price)} ₽`}</SCBuyBook>
    </BookLink>
  );

  if (
    book.statusComplete === CompleteStatusEnum.Freeze
    || book.statusComplete === CompleteStatusEnum.Reference
  ) {
    return null;
  }

  if (book?.myBook?.buy) {
    return <SCBuyBookText>Куплена</SCBuyBookText>;
  }

  if (book.free) {
    return <SCFreeBookText>Бесплатно</SCFreeBookText>;
  }

  if (isHaveDiscount) {
    return (
      <>
        <SCPrice>
          <SCOldPrice>{`${book?.price} ₽`}</SCOldPrice>
          <BuyBook />
        </SCPrice>
      </>
    );
  }

  return (
    <SCPrice>
      <BuyBook />
    </SCPrice>
  );
};

const SCPrice = styled.span`
  ${TextBoldStyle};
  color: var(--black-color);
  position: relative;
`;

const SCOldPrice = styled.span`
  font-family: Gilroy;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  text-decoration-line: line-through;
  color: var(--gray-color);
  margin-right: 12px;
`;

const SCFreeBookText = styled.span`
  font-family: var(--second-font);
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: var(--black-color);
  margin-right: 4px;
`;

const SCBuyBookText = styled.span`
  font-family: var(--second-font);
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: var(--gray-color);
  margin-right: 4px;
  text-transform: uppercase;
`;

const SCBuyBook = styled.a`
  ${TextBoldStyle};
  color: var(--black-color);
`;
