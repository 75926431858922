import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

import { CoverImage, MiniatureSizesEnum } from '~/api/book/bookApiTypes';
import { DefaultBookCover } from '~/atomic/atom/DefaultBookCover';
import { getImageUrlBySize, sortByType } from '~/atomic/atom/SimpleCover';
import { isSupportWebpSelector } from '~/feature/supportWebp/isSupportWebp.selector';
import { useAppSelector } from '~/store';

type CoverMedia = {
  S: string;
  M: string;
  L: string;
};

interface BookCoverProps {
  media?: CoverMedia;
  book?: {
    title: string;
    author: string;
  };
  coverImages: CoverImage[];
}

export const BookCardCover: FC<BookCoverProps & HTMLAttributes<HTMLImageElement>> = ({
  book, coverImages, media, ...props
}) => {
  if (!coverImages || coverImages.length === 0) {
    return (
      <SCBookCover>
        <SCImageContent>
          <DefaultBookCover
            bookName={book?.title ?? 'Название книги'}
            authorName={book?.author ?? 'Имя Автора'}
            font={{
              min: 4,
              max: 16,
            }}
          />
        </SCImageContent>
      </SCBookCover>
    );
  }

  const { jpeg, webp } = sortByType(coverImages);
  const isSupportWebp = useAppSelector(isSupportWebpSelector);

  let srcSet: string;

  if (isSupportWebp) {
    srcSet = `${getImageUrlBySize(webp, MiniatureSizesEnum.S)} 100w, `
    + `${getImageUrlBySize(webp, MiniatureSizesEnum.M)} 160w, `
    + `${getImageUrlBySize(webp, MiniatureSizesEnum.L)} 320w`;
  } else {
    srcSet = `${getImageUrlBySize(jpeg, MiniatureSizesEnum.S)} 100w, `
    + `${getImageUrlBySize(jpeg, MiniatureSizesEnum.M)} 160w, `
    + `${getImageUrlBySize(jpeg, MiniatureSizesEnum.L)} 320w`;
  }

  return (
    <SCBookCover>
      <SCBookCoverPicture>
        <img
          loading="lazy"
          decoding="async"
          src={getImageUrlBySize(jpeg, MiniatureSizesEnum.Original)}
          srcSet={srcSet}
          alt={`${book?.title ?? 'Название книги'} - ${book?.author ?? 'Автор книги'}`}
          sizes="(min-width: 445px) and (max-width: 756px) 320px,
          (min-width: 768px) and (max-width: 846px) 320px,
          (min-width: 1024px) 320px, (min-width: 625px) and (max-width: 767px) 160px,
          (min-width: 847px) and (max-width: 1023px) 160px, (max-width: 445px) 60px"
          {...props}
        />
      </SCBookCoverPicture>
    </SCBookCover>
  );
};

const SCBookCoverPicture = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  background-color: var(--gray2-color);

  img {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: auto;
    image-rendering: crisp-edges;
  }
`;

const SCBookCover = styled.div`
  display: block;
  width: 100%;
  padding-bottom: var(--image-aspect);
  position: relative;
`;

export const SCImageContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;
