import React from 'react';
import styled from 'styled-components';

import { MyBookStatusEnum, MyBookStatusTextEnum } from '~/api/account/myBooksApiTypes';
import { HideEyeIcon } from '~/atomic/atom/icon/HideEyeIcon';
import { MyBooksStatusIcon } from '~/atomic/atom/MyBooksStatusIcon';

import { ReadBookStatus } from './ReadBookStatus';

interface ReadBookStatusListProps {
  status: MyBookStatusEnum;
  setStatus: (status: MyBookStatusEnum) => void;
  onDelete: () => void;
  onHide?: () => void;
  isShowHideBook?: boolean;
}

export const ReadBookStatusList: React.FC<ReadBookStatusListProps> = ({
  status = { status: '' },
  setStatus,
  onDelete,
  onHide,
  isShowHideBook = false,
}) => {
  return (
    <SCStatusMenuList>
      {status !== MyBookStatusEnum.readNow && (
        <>
          <ReadBookStatus
            icon={(
              <SCReadersIcon status={MyBookStatusEnum.readNow} />
            )}
            text={MyBookStatusTextEnum[MyBookStatusEnum.readNow]}
            onClick={() => setStatus(MyBookStatusEnum.readNow)}
          />
        </>
      )}
      {status !== MyBookStatusEnum.readLater && (
        <>
          <ReadBookStatus
            icon={(
              <SCBookmarkIcon status={MyBookStatusEnum.readLater} />
            )}
            text={MyBookStatusTextEnum[MyBookStatusEnum.readLater]}
            onClick={() => setStatus(MyBookStatusEnum.readLater)}
          />
        </>
      )}
      {status !== MyBookStatusEnum.read && (
        <>
          <ReadBookStatus
            icon={(
              <SCBooksIcon status={MyBookStatusEnum.read} />
            )}
            text={MyBookStatusTextEnum.read}
            onClick={() => setStatus(MyBookStatusEnum.read)}
          />
        </>
      )}
      {(status && status !== MyBookStatusEnum.NotRead) && (
        <>
          <ReadBookStatus
            src="/icon/delete.svg"
            text={MyBookStatusTextEnum.delete}
            onClick={onDelete}
          />
        </>
      )}
      {isShowHideBook && (
        <>
          <ReadBookStatus
            icon={(
              <SCHideEyeIcon />
            )}
            text="Не показывать"
            onClick={onHide && onHide}
          />
        </>
      )}
    </SCStatusMenuList>
  );
};

export const SCStatusMenuList = styled.ul`
  width: 100%;
`;

const SCHideEyeIcon = styled(HideEyeIcon)`
  font-size: 20px;
  color: var(--black-color);
`;

const SCReadersIcon = styled(MyBooksStatusIcon)`
  font-size: 20px;
  color: var(--black-color);
  flex-shrink: 0;
`;

const SCBookmarkIcon = styled(MyBooksStatusIcon)`
  font-size: 18px;
  color: var(--black-color);
  flex-shrink: 0;
`;

const SCBooksIcon = styled(MyBooksStatusIcon)`
  font-size: 18px;
  color: var(--black-color);
  flex-shrink: 0;
`;
