import React, { FC } from 'react';
import styled from 'styled-components';

import { isMobileSizeSelector } from '~/feature/media/windowSize.selector';
import { getReactionIconUrl } from '~/feature/myBooks/reactions/reactionsList';
import { BookReaction } from '~/graphql/books/factory/book/BookFactoryTypes';
import { lessThan } from '~/lib/mediaQuery';
import { useAppSelector } from '~/store';

export interface ReactionBadgerProps extends React.HTMLAttributes<HTMLDivElement> {
  reaction?: BookReaction;
  className?: string;
}

export const ReactionBadger: FC<ReactionBadgerProps> = ({
  className, reaction, ...props
}) => {
  const isMobileSize = useAppSelector(isMobileSizeSelector);
  const imageSize = isMobileSize ? 26 : 34;

  return (
    <SCReactionBadger className={className} {...props}>
      <img
        width={imageSize}
        height={imageSize}
        src={getReactionIconUrl(reaction?.rating)}
        alt="реакция"
      />
    </SCReactionBadger>
  );
};

const SCReactionBadger = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white-color);
  

  ${lessThan('sm')} {
    height: 32px;
    max-width: 32px;
  }

  ${lessThan('xs')} {
    height: 28px;
    max-width: 28px;
  }
`;
