import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { TextBoldStyle } from '~/atomic/Typography';

interface ReadBookStatusProps {
  src?: string;
  icon?: ReactNode;
  text: string;
  onClick: () => void;
}

export const ReadBookStatus: React.FC<ReadBookStatusProps> = ({
  src, text, onClick, icon,
}) => {
  return (
    <SCReadBookStatus onClick={onClick}>
      {icon ? (
        <SCIconWrap>
          {icon}
        </SCIconWrap>
      ) : (
        <SCIconWrap>
          <SCStatusIcon src={src} alt="" />
        </SCIconWrap>
      )}
      <SCText>{text}</SCText>
    </SCReadBookStatus>
  );
};

const SCReadBookStatus = styled.li`
  display: flex;
  align-items: center;
  transition: 0.2s;
  cursor: pointer;
  padding: 6px 4px;
  border-radius: 2px;
  
  &:hover {
    background: var(--gray2-color);
  }
`;

const SCIconWrap = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

const SCStatusIcon = styled.img`
`;

const SCText = styled.span`
  ${TextBoldStyle};
  color: var(--black-color);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
