import styled, { FlattenSimpleInterpolation } from 'styled-components';

import { TextBoldStyle, TextRegStyle } from '~/atomic/Typography';
import { lessThan } from '~/lib/mediaQuery';

export const SCBookTitle = styled.a`
  ${TextBoldStyle};
  
  --font-size: 14px;
  --line-height: 20px;
  
  color: var(--black-color);
  
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;

  line-height: var(--line-height);
  font-size: var(--font-size);
  
  height: var(--font-size) * var(--line-height) * 2; /* Fallback for non-webkit */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  
  &:hover {
    color: var(--primary-hover-color);
  }
  
  ${lessThan('xxs')} {
    --font-size: 12px;
    --line-height: 16px;
  }
`;

export const SCBookCardAuthor = styled.a`
  ${TextRegStyle};

  --font-size: 13px;
  --line-height: 20px;
  
  font-size: var(--font-size);
  line-height: var(--line-height);

  display: block; /* Fallback for non-webkit */
  display: -webkit-box;    
  color: var(--black-color);

  max-width: 100%;
  height: var(--font-size) * var(--line-height) * 2; /* Fallback for non-webkit */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  
  &:hover {
    color: var(--primary-hover-color);
  }
  
  ${lessThan('xxs')} {
    --font-size: 12px;
    --line-height: 16px;
  }
`;

export const SCBookInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
`;

export const SCDiscountBadgeBookCard = styled.div<{ css: FlattenSimpleInterpolation }>`
  background: #F2C94C;
  border: 2px solid var(--white-color);
  color: var(--black-color);
  box-sizing: border-box;
  width: 100%;
  max-width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--main-font);
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 20px;
  margin-right: auto !important;

  ${lessThan('sm')} {
    height: 32px;
    max-width: 32px;
    font-size: 10px;
    line-height: 14px;
  }

  ${lessThan('xs')} {
    height: 28px;
    max-width: 28px;
    font-size: 10px;
    line-height: 14px;
  }

  @keyframes BadgeGlossEffect {
    from {
      background-color: #E3AE0C;
    }

    50% {
      background-color: #F6ECCC;
    }

    to {
      background-color: #E3AE0C;
    }
  }

  animation-duration: 3s;
  animation-name: BadgeGlossEffect;
  animation-delay: 1s;
  animation-iteration-count: infinite;
  
  ${(props) => props.css}
`;

export const SCPartnerPercentBadgeBookCard = styled.div`
  background: var(--green2-color);
  box-sizing: border-box;
  width: 100%;
  max-width: max-content;
  height: 36px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--main-font);
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #fff;
  padding: 4px;

  ${lessThan('xs')} {
    height: 28px;
    padding: 2px;
    font-size: 12px;
    line-height: 14px;
  }
`;
