import React, { FC } from 'react';
import styled from 'styled-components';

interface BookCardExclusiveProps {
  size?: 'small' | 'normal' | 'large';
  isExclusive: boolean;
}

export const BookCardExclusive: FC<BookCardExclusiveProps> = ({
  size = 'normal',
  isExclusive,
}) => {
  if (!isExclusive) {
    return <></>;
  }

  return (
    <SCBookCardExclusive
      data-size={size}
    >
      <SCText>
        Эксклюзив
      </SCText>
    </SCBookCardExclusive>
  );
};

const SCText = styled.span`
  font-size: 10px;
  font-weight: bold;
  color: var(--black-color);
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(45deg);
  width: 100px;
  display: block;
  background: var(--yellow-dark-color);
  box-shadow: 0 3px 10px -5px black;
  position: absolute;
  top: 19px;
  right: -21px;
  z-index: 3;

  &:before {
    content: "";
    position: absolute;
    left: -1px;
    top: 14px;
    z-index: -1;
    background-color: var(--yellow-dark-color);
    width: 12px;
    height: 6px;
    transform: rotate(-45deg);
  }
  
  &:after {
    content: "";
    position: absolute;
    right: -2px;
    bottom: -1px;
    z-index: -1;
    background-color: var(--yellow-dark-color);
    width: 12px;
    height: 6px;
    transform: rotate(45deg);
  }
`;

const SCBookCardExclusive = styled.div`
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 3;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
  
  &[data-size="large"] {
    width: 100px;
    height: 100px;

    ${SCText} {
      top: 30px;
      right: -37px;
      width: 150px;
      font-size: 14px;
      line-height: 23px;

      &:before {
        left: 3px;
        top: 19px;
      }
      
      &:after {
        right: 7px;
        bottom: -3px;
      }
    }
  }
`;
