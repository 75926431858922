import { BookReactionRatingEnum, CamelCaseBookReactionRatingEnum } from '~/graphql/books/factory/book/BookFactoryTypes';

export interface Reaction {
  name: BookReactionRatingEnum;
  camelCaseName: CamelCaseBookReactionRatingEnum;
  label: string;
  icon: string;
  animationHover: string;
  animationClick: string;
}

export const reactionsList: Array<Reaction> = [
  {
    name: BookReactionRatingEnum.Great,
    camelCaseName: CamelCaseBookReactionRatingEnum.Great,
    label: 'В восторге от книги!',
    icon: '/image/reactions/great.png',
    animationHover: '/animations/reactions/hover/great-hover.gif',
    animationClick: '/animations/reactions/click/great-click.gif',
  },
  {
    name: BookReactionRatingEnum.Well,
    camelCaseName: CamelCaseBookReactionRatingEnum.Well,
    label: 'Хорошая книга,\nприятные впечатления',
    icon: '/image/reactions/good.png',
    animationHover: '/animations/reactions/hover/good-hover.gif',
    animationClick: '/animations/reactions/click/good-click.gif',
  },
  {
    name: BookReactionRatingEnum.WillDo,
    camelCaseName: CamelCaseBookReactionRatingEnum.WillDo,
    label: 'Читать можно',
    icon: '/image/reactions/normal.png',
    animationHover: '/animations/reactions/hover/normal-hover.gif',
    animationClick: '/animations/reactions/click/normal-click.gif',
  },
  {
    name: BookReactionRatingEnum.SoSo,
    camelCaseName: CamelCaseBookReactionRatingEnum.SoSo,
    label: 'Могло быть и лучше',
    icon: '/image/reactions/bad.png',
    animationHover: '/animations/reactions/hover/bad-hover.gif',
    animationClick: '/animations/reactions/click/bad-click.gif',
  },
  {
    name: BookReactionRatingEnum.Bad,
    camelCaseName: CamelCaseBookReactionRatingEnum.Bad,
    label: 'Книга не для меня',
    icon: '/image/reactions/terrible.png',
    animationHover: '/animations/reactions/hover/terrible-hover.gif',
    animationClick: '/animations/reactions/click/terrible-click.gif',
  },
  {
    name: BookReactionRatingEnum.NotRated,
    camelCaseName: CamelCaseBookReactionRatingEnum.NotRated,
    label: 'Не могу оценить',
    icon: '/image/reactions/cannot_rate.png',
    animationHover: '/animations/reactions/hover/cannot_rate-hover.gif',
    animationClick: '/animations/reactions/click/cannot_rate-click.gif',
  },
];

export const getReactionIconUrl = (reactionName: BookReactionRatingEnum) => reactionsList.find((reaction) => reaction.name === reactionName)?.icon ?? '/image/reactions/cannot_rate.png';
