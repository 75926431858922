import { handleRejectedRequest } from '~/api/provider/providerErrors';
import { ApiResponse, RejectedRequest } from '~/api/provider/providerTypes';
import {
  BookFeedbackParams,
  DeleteBookFeedbackParams,
} from '~/api/recommendations/recommendationsApiTypes';

import { provider } from '../provider/provider.config';

const namespace = Symbol('namespace');

export const recommendationsApi = {
  [namespace]: '/v1/recommendations',

  async bookFeedback(data: BookFeedbackParams) {
    const { bookId, ...requestData } = data;
    try {
      const response = await provider.post<
      ApiResponse<void, RejectedRequest<void>>
      >(
        `${this[namespace]}/books/${bookId}/feedback`,
        requestData,
      );

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async deleteBookFeedback(data: DeleteBookFeedbackParams) {
    try {
      const response = await provider.delete<
      ApiResponse<void, RejectedRequest<void>>
      >(
        `${this[namespace]}/books/${data.bookId}/feedback`,
      );

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
};
