/* eslint-disable max-len */
import React, { SVGAttributes } from 'react';

import { SCSVGIcon } from './SCCIcon';

export const ListenIcon = (props: SVGAttributes<SVGSVGElement>) => {
  return (
    <SCSVGIcon {...props} viewBox="0 0 21 19" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.5 0.75C12.2969 0.75 13.9766 1.21875 15.5 2.11719C17.0234 3.01562 18.2344 4.22656 19.1328 5.75C20.0312 7.27344 20.5 8.95312 20.5 10.75V12.7422C20.5 13.0156 20.3828 13.2109 20.1875 13.2891L19.25 13.8359V13.875C19.25 14.6562 19.0547 15.3984 18.6641 16.0625C18.2734 16.7656 17.7266 17.2734 17.0625 17.6641C16.3594 18.0547 15.6562 18.25 14.875 18.25H13.9375C13.6641 18.25 13.4297 18.1719 13.2734 17.9766C13.0781 17.8203 13 17.5859 13 17.3125V10.4375C13 10.2031 13.0781 9.96875 13.2734 9.77344C13.4297 9.61719 13.6641 9.5 13.9375 9.5H14.875C15.8125 9.5 16.6719 9.8125 17.4531 10.3594C18.1953 10.9062 18.7031 11.6484 19.0156 12.5078L19.25 12.3906V10.75C19.25 9.1875 18.8594 7.70312 18.0781 6.375C17.2969 5.04688 16.2031 3.95312 14.875 3.17188C13.5469 2.39062 12.0625 2 10.5 2C8.89844 2 7.41406 2.39062 6.08594 3.17188C4.75781 3.95312 3.70312 5.04688 2.92188 6.375C2.14062 7.70312 1.75 9.1875 1.75 10.75V12.3906L1.98438 12.5078C2.25781 11.6484 2.76562 10.9062 3.54688 10.3594C4.28906 9.8125 5.14844 9.5 6.125 9.5H7.0625C7.29688 9.5 7.53125 9.61719 7.72656 9.77344C7.88281 9.96875 8 10.2031 8 10.4375V17.3125C8 17.5859 7.88281 17.8203 7.72656 17.9766C7.53125 18.1719 7.29688 18.25 7.0625 18.25H6.125C5.34375 18.25 4.60156 18.0547 3.9375 17.6641C3.23438 17.2734 2.72656 16.7656 2.33594 16.0625C1.94531 15.3984 1.75 14.6562 1.75 13.875V13.8359L0.8125 13.2891C0.578125 13.2109 0.5 13.0156 0.5 12.7422V10.75C0.5 8.95312 0.929688 7.27344 1.82812 5.75C2.72656 4.22656 3.9375 3.01562 5.46094 2.11719C6.98438 1.21875 8.66406 0.75 10.5 0.75ZM6.125 10.75C5.26562 10.75 4.52344 11.0625 3.89844 11.6875C3.27344 12.3125 3 13.0156 3 13.875C3 14.7344 3.27344 15.4766 3.89844 16.1016C4.52344 16.7266 5.26562 17 6.125 17H6.75V10.75H6.125ZM14.875 17C15.7344 17 16.4375 16.7266 17.0625 16.1016C17.6875 15.4766 18 14.7344 18 13.875C18 13.0156 17.6875 12.3125 17.0625 11.6875C16.4375 11.0625 15.7344 10.75 14.875 10.75H14.25V17H14.875Z" />
    </SCSVGIcon>
  );
};
