import React, { FC } from 'react';
import styled from 'styled-components';

import { Book } from '~/api/book/bookApiTypes';
import { HideEyeIcon } from '~/atomic/atom/icon/HideEyeIcon';
import { cancelHiddenBook } from '~/atomic/page/mybooks/myBooks.data';
import { TextRegStyle } from '~/atomic/Typography';
import { useAppDispatch } from '~/store';

interface BookCardHideBlockProps {
  book: Book;
  isHideBook: boolean;
  setIsHideBook: (isHide: boolean) => void;
}

export const BookCardHideBlock: FC<BookCardHideBlockProps> = ({
  book, isHideBook, setIsHideBook,
}) => {
  const dispatch = useAppDispatch();

  const cancelHideBookHandler = async () => {
    const result = await dispatch(cancelHiddenBook({ bookId: book.id }));

    if (cancelHiddenBook.fulfilled.match(result)) {
      setIsHideBook(false);
    }
  };

  if (!isHideBook) return <></>;

  return (
    <SCCancelHideBookOverlay>
      <SCCancelHideBookIcon />
      <SCCancelHideBookText>
        Книга больше
        не покажется
      </SCCancelHideBookText>
      <SCCancelHideBookButton
        onClick={cancelHideBookHandler}
      >
        Отменить
      </SCCancelHideBookButton>
    </SCCancelHideBookOverlay>
  );
};

const SCCancelHideBookOverlay = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(6px);
  z-index: 2;
`;

const SCCancelHideBookIcon = styled(HideEyeIcon)`
  font-size: 20px;
  color: var(--gray-color);
`;

const SCCancelHideBookText = styled.span`
  ${TextRegStyle};
  color: var(--black-color);
  max-width: 140px;
  display: flex;
  padding-top: 12px;
`;

const SCCancelHideBookButton = styled.span`
  ${TextRegStyle};
  color: var(--primary-color);
  text-decoration: underline;
  padding-top: 12px;
  display: flex;
  cursor: pointer;
`;
